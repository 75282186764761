import { Luminous, LuminousGallery } from "luminous-lightbox";
import 'luminous-lightbox/dist/luminous-basic.min.css';

export default class {
    constructor() {
        this.targetClass = ".js-lightbox__elem";
        this.init();
    }
    init() {
        const targetElems = document.querySelectorAll(this.targetClass);
        if( targetElems.length > 0 ) {
            if( targetElems.length > 1 ) {
                new LuminousGallery(targetElems);
            } else if( targetElems[0] ) {
                new Luminous(targetElems[0]);
            }
        }
    }
}