import {gsap} from "gsap";
// const UAParser = require('ua-parser-js');

export default class {
    constructor() {
        // this.stalker();
        // this.stalkerImgService();
        this.mouseStalkerArea = ".js-stalker__area";
        this.mouseCursor = "#js-stalker__cursor";
        this.mouseStalkerService = ('#js-stalker__service');
        this.mouseStalkerImg = ('#js-stalker__service--img');
        this.hoverClass = "is-hover";

        this.mouseStalker();
    }

    mouseStalker() {
        const areas = document.querySelectorAll(this.mouseStalkerArea);
        const cursor = document.querySelector(this.mouseCursor);
        const areaService = document.querySelector(this.mouseStalkerService);
        const Img = document.querySelector(this.mouseStalkerImg);
        const cursorWidth = 96;
        const imgWidth = 470;
        let mouseX = 0;
        let mouseY = 0;
        if(cursor) {

            document.addEventListener('mousemove', (e) => {
                mouseX = e.clientX;
                mouseY = e.clientY;

                cursor.style.transform = "translate(" + parseInt(mouseX - (cursorWidth / 2)) + "px," + parseInt(mouseY - (cursorWidth / 2)) + "px)";
                Img.style.transform = "translate(" + parseInt( 100 + mouseX - (imgWidth / 2)) + "px," + parseInt(100 + mouseY - (imgWidth / 2)) + "px)";
            });
            if(areas.length > 0) {
                areas.forEach( (area) => {
                    area.addEventListener('mouseenter', (e) => {
                        cursor.style.opacity = "1";
                        cursor.style.visibility = "visible";
                    });
                    // 要素から外に出たとき、非表示
                    area.addEventListener('mouseleave', (e) => {
                        cursor.style.opacity = "0";
                        cursor.style.visibility = "hidden";
                    });
                })
            }
            const path = document.querySelector('#serviceList');
            if(areaService) {
                const pathDir = path.dataset.tmpdir;
                const imgPath = pathDir + '/img/index/';
                const items = document.querySelectorAll('.js-stalker__service--item');
                const imgArr = [
                    imgPath + 'thumbnail-service01.jpg',
                    imgPath + 'thumbnail-service02.jpg',
                    imgPath + 'thumbnail-service03.jpg',
                    imgPath + 'thumbnail-service04.jpg',
                ];
                const itemSlides = document.querySelectorAll('.js-stalker__service--img-slide');
                // 要素内に入ったとき、styleを追加
                areaService.addEventListener('mouseenter', (e) => {
                    Img.style.opacity = "1";
                    Img.style.visibility = "visible";
                });
                // 要素から外に出たとき、非表示
                areaService.addEventListener('mouseleave', (e) => {
                    Img.style.opacity = "0";
                    Img.style.visibility = "hidden";
                });
                items.forEach( (item, i) => {
                    item.addEventListener('mouseenter', (e) => {
                        // Img.style.backgroundImage = `url("${imgArr[i]}")`;
                        // itemSlides[i].classList.add('is-active');
                        const enterSlide = Img.querySelectorAll('.js-stalker__service--img-slide');
                        // console.log(enterSlide[i]);
                        enterSlide[i].classList.add('is-active');
                        itemSlides[i].style.backgroundImage = `url("${imgArr[i]}")`;
                    });
                    item.addEventListener('mouseleave', (e) => {
                        const leaveSlide = Img.querySelectorAll('.js-stalker__service--img-slide');
                        leaveSlide[i].classList.remove('is-active');
                    });
                });
            }
        }
    }
}