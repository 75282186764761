import {gsap} from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class {
    constructor() {
        const bodyScrollLock = require('body-scroll-lock');
        // スクロールをロックする処理
        this.disableBodyScroll = bodyScrollLock.disableBodyScroll;
        // ロックを解除する処理
        this.enableBodyScroll = bodyScrollLock.enableBodyScroll;
        // オプション
        this.options = {
            /**
             * bodyにスクロールバー分のpadding-leftを追加するか
             * default: false
             */
            reserveScrollBarGap: true,
        }

        this.loading = '#js-loading';
        this.logo = '#js-loading__logo';

        this.wrapper = '#js-wrapper';
        this.activeClass = 'is-active';

        this.colorPrimary = '#28543C';
        this.colorSecondary = '#62C0B4';
        this.colorWhite = '#fff';
        // this.loadingSplash();
        // setTimeout(() =>{
        //     this.loadingFade();
        // }, 3000)
        this.init();
    }
    init() {
        const keyName = 'visited';
        const keyValue = true;
        if (!sessionStorage.getItem(keyName)) {
            sessionStorage.setItem(keyName, keyValue);
            this.loadingSplash();
            setTimeout(() =>{
                this.loadingFade();
            }, 3000)
        } else {
            setTimeout(() =>{
                this.loadingFade();
            }, 500)
        }
    }

    loadingSplash() {
        const loading = document.querySelector(this.loading);
        if(loading) {
            loading.style.display = 'block';
            const logo = document.querySelector(this.logo);
            const path = logo.querySelectorAll('path');
            path.forEach((el) => {
                const primary = gsap.utils.toArray( this.logo + " .-primary");
                const secondary = gsap.utils.toArray( this.logo + " .-secondary");
                const white = gsap.utils.toArray( this.logo + " .-white");

                // gsap.registerPlugin(PixiPlugin, MotionPathPlugin);
                let tl = gsap.timeline();
                tl
                .fromTo(el,  
                    {
                        opacity: 0
                    },
                    {
                        opacity: 1
                    },
                .3,)
                .fromTo(el, 10,
                    {
                        'stroke-dashoffset': '1200px'
                    },
                    {
                        'stroke-dashoffset': '0',
                        stagger:0.4
                    },
                1, )
                .fromTo(primary, .3,
                    {
                        'fill': 'none'
                    },
                    {
                        'fill': this.colorPrimary,
                        'stroke-width': '0'
                    },
                2.2, )
                .fromTo(secondary, .3,
                    {
                        'fill': 'none'
                    },
                    {
                        'fill': this.colorSecondary,
                        'stroke-width': '0'
                    },
                2.2, )
                .fromTo(white, .3,
                    {
                        'fill': 'none'
                    },
                    {
                        'fill': this.colorWhite,
                        'stroke-width': '0'
                    },
                2.2, )
            })
        }
    }
    loadingFade() {
        const loading = document.querySelector(this.loading);
        const wrapper = document.querySelector(this.wrapper);
        if(loading && wrapper) {
            loading.style.opacity = '0';
            // loading.style.display = 'none';
            wrapper.classList.add(this.activeClass);
            setTimeout(() =>{
                loading.style.display = 'none';
            }, 800)
        }
    }
}