import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
    constructor() {
        this.heroSlider();
    }
    heroSlider() {
        const heroSlider = '#js-slider__hero';
        new Swiper (heroSlider,{
            effect:'slide',
            speed:1000,
            loop: true,
            easing: "easeOutExpo",
            autoplay: {
                delay: 10000
            },
            slidesPerView: 1,
            spaceBetween: 0,
            pagination: {
                el: "#js-slider__hero--pagination",
                clickable: true,
            },
            // navigation: {
            //     nextEl: "#js-slider__recommend--next",
            //     prevEl: "#js-slider__recommend--prev"
            // },
        })
    }
    // recommendSlider(){
    //     const recommendSlider = '#js-slider__recommend';
    //     new Swiper (recommendSlider,{
    //         effect:'slide',
    //         speed:1000,
    //         easing: "easeOutExpo",
    //         // autoplay: {
    //         //     delay: 4000
    //         // },
    //         slidesPerView: 1.85,
    //         spaceBetween: 60,
    //         pagination: {
    //             el: "#js-slider__recommend--pagination",
    //             clickable: true,
    //         },
    //         navigation: {
    //             nextEl: "#js-slider__recommend--next",
    //             prevEl: "#js-slider__recommend--prev"
    //         },
    //     })
    // }
    // propertySlider(){
    //     const propertySlider = '#js-slider__property';
    //     new Swiper (propertySlider, {
    //         effect:'slide',
    //         speed:1000,
    //         loop: true,
    //         easing: "easeOutExpo",
    //         autoplay: {
    //             delay: 4000
    //         },
    //         slidesPerView: 2.75,
    //         centeredSlides:true,
    //         spaceBetween: 60,
    //         pagination: {
    //             el: "#js-slider__property--pagination",
    //             clickable: true,
    //         },
    //         // navigation: {
    //         //     nextEl: "#js-slider__recommend--next",
    //         //     prevEl: "#js-slider__recommend--prev"
    //         // },
    //     })
    // }


}