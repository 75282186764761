import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

export default class {
    constructor() {
        this.wrapper = '#js-scroll__hero';
        this.section = '.js-scroll__hero--section';
        this.catch = '.js-scroll__hero--catch';
        this.illustration = '.js-scroll__hero--illust';

        this.circleWrapper = '#js-scroll__hero--circle';
        this.circle = '#js-scroll__hero--circle-elem';
        this.circleArrow = '#js-scroll__hero--circle-arrow';
        this.circleLink = '#js-scroll__hero--circle-link'

        this.bg = '#js-scroll__hero--bg';
        this.copy = '#js-scroll__hero--copy';


        this.navigation = "#js-scroll__hero--navigation"
        this.anchor = ".js-scroll__hero--anchor";
        this.skip = "#js-scroll__hero--skip"

        // const hero01 = document.querySelector('.INDEX01');
        // if(hero01) {
            this.init();
            this.anchorScroll();

            // window.addEventListener('resize', () => {
            //     // let windowWidth = parseInt(window.innerWidth);
            //     // if(windowWidth >= 1320) {
                    
            //     // }
            //     this.init();
            //     this.anchorScroll();
            // })
        // }
    }


    init() {

        const wrapper = document.querySelector(this.wrapper);
        if(wrapper) {
            gsap.registerPlugin(ScrollTrigger);
            const sections = gsap.utils.toArray(this.section);
            const catches = gsap.utils.toArray(this.catch);
            const illustrations = gsap.utils.toArray(this.illustration);
            const anchors = gsap.utils.toArray(this.anchor);

            const circleWrapper = document.querySelector(this.circleWrapper);
            const circle = document.querySelector(this.circle);
            const circleArrow = document.querySelector(this.circleArrow);
            const circleLink = document.querySelector(this.circleLink);
            const circlePath = circle.querySelectorAll("path");
            const circleWidth = circleWrapper.clientWidth;

            const backGround = document.querySelector(this.bg);
            const copy = document.querySelector(this.copy);

            const navigation = document.querySelector(this.navigation);
            const skip = document.querySelector(this.skip);

            const wrapperWidth = wrapper.offsetWidth;
            const sectionWidth = wrapperWidth / 4;

            /**
             * 横スクロール
             */
            gsap.to( sections, {
                xPercent: -100 * (sections.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: wrapper,
                    pin: true,
                    scrub: 1,
                    // snap: 1 / (sections.length - 1),
                    end: () => "+=" + wrapperWidth
                }
            } )
            /**
             * 円の回転
             */
            gsap.to(circle, { 
                duration: 10,
                rotation: 360,
                scrollTrigger: {
                    trigger: wrapper,
                    // pin: true,
                    scrub: 2,
                    // snap: 1 / sections.length,
                    start: 0,
                    end: () => "+=" + wrapperWidth
                }
             });

            /**
             * 
             * MV 01
             * 
            */
            const mv01 = gsap.timeline()
            mv01.to( catches[0], {
                ease: "none",
                opacity: 0,
                scrollTrigger: {
                    trigger: sections[0],
                    scrub: 1,
                    // markers: true,
                    start: 0,
                    end: () => "+=" + sectionWidth / 4
                }
            } )
            mv01.to( illustrations[0], {
                ease: "none",
                opacity: 0,
                // x: -100,
                scrollTrigger: {
                    trigger: sections[0],
                    scrub: 1,
                    // markers: true,
                    start: 0,
                    end: () => "+=" + sectionWidth / 2
                }
            } )
            ScrollTrigger.create({
                trigger: anchors[0],
                start: -1,
                end: sectionWidth,
                toggleClass: 'is-active',
            });

            /**
             * 
             * MV 02
             * 
            */
             const mv02 = gsap.timeline()
             mv02.to( catches[1], {
                 ease: "none",
                 opacity: 0,
                 scrollTrigger: {
                    trigger: sections[1],
                    scrub: 1,
                    // markers: true,
                    start: "+=" + sectionWidth * 1.333,
                    //  end: () => "+=" + sectionWidth
                    end: "+=" + sectionWidth / 4
                 }
             } )
             mv02.to( illustrations[1], {
                 ease: "none",
                 opacity: 0,
                 scrollTrigger: {
                     trigger: sections[1],
                     scrub: 1,
                    //  markers: true,
                     start: "+=" + sectionWidth * 1.5,
                     end: "+=" + sectionWidth / 4
                 }
             } )
             ScrollTrigger.create({
                trigger: anchors[1],
                start: sectionWidth - 1,
                end: sectionWidth * 2,
                toggleClass: 'is-active',
            });

            /**
             * 
             * MV 03
             * 
            */
            const mv03 = gsap.timeline()
            mv03.to( catches[2], {
                ease: "none",
                opacity: 0,
                scrollTrigger: {
                    trigger: sections[2],
                    scrub: 1,
                //  markers: true,
                    start: "+=" + sectionWidth * 2.666,
                    //  end: () => "+=" + sectionWidth
                    end: "+=" + sectionWidth / 4
                }
            } )
            mv03.to( illustrations[2], {
                ease: "none",
                opacity: 0,
                scrollTrigger: {
                    trigger: sections[2],
                    scrub: 1,
                    // markers: true,
                    start: "+=" + sectionWidth * 3,
                    end: "+=" + sectionWidth / 4
                }
            } )
            ScrollTrigger.create({
                trigger: anchors[2],
                start: sectionWidth * 2 - 1,
                end: sectionWidth * 3,
                toggleClass: 'is-active',
            });

            /**
             * 
             * MV 04
             * 
            */
            const mv04 = gsap.timeline()
            mv04.to( circleLink, {
                ease: "none",
                opacity: 0,
                pointerEvents: "none",
                scrollTrigger: {
                    trigger: sections[3],
                    scrub: 1,
                    start: "+=" + sectionWidth * 3,
                    end: "+=" + 0
                }
            } )
            mv04.to( navigation, {
                ease: "none",
                opacity: 0,
                pointerEvents: "none",
                scrollTrigger: {
                    trigger: sections[3],
                    scrub: 1,
                    start: "+=" + sectionWidth * 3,
                    end: "+=" + sectionWidth / 4
                }
            } )
            mv04.to( skip, {
                ease: "none",
                opacity: 0,
                pointerEvents: "none",
                scrollTrigger: {
                    trigger: sections[3],
                    scrub: 1,
                    start: "+=" + sectionWidth * 3,
                    end: "+=" + 0
                }
            } )
            circlePath.forEach( (path) => {
                mv04.to( path, {
                    ease: "none",
                    fill: "#fff",
                    scrollTrigger: {
                        trigger: sections[3],
                        scrub: 1,
                        start: "+=" + sectionWidth * 2.8,
                        end: "+=" + sectionWidth / 2
                    }
                } )
            })
            mv04.to( circleArrow, {
                ease: "none",
                opacity: 0,
                scrollTrigger: {
                    trigger: sections[3],
                    scrub: 1,
                    start: "+=" + sectionWidth * 2.8,
                    end: "+=" + sectionWidth / 2
                }
            } )
            mv04.to( backGround, {
                ease: "none",
                scale: 2,
                duration: 100,
                scrollTrigger: {
                    trigger: sections[3],
                    scrub: 1,
                    start: "+=" + sectionWidth * 3.2,
                    end: "+=" + sectionWidth / 2
                }
            } )
            mv04.to( copy, {
                ease: "none",
                opacity: 1,
                duration: 100,
                scrollTrigger: {
                    trigger: sections[3],
                    scrub: 1,
                    start: "+=" + sectionWidth * 3.2,
                    end: "+=" + sectionWidth / 2
                }
            } )
            mv04.to( circleWrapper, {
                ease: "none",
                // left: 0,
                left: "12.5%",
                // left: "6.25%",
                // left: "-3.125%",
                // x: "-" + ( ( sectionWidth  - circleWidth ) / 2 ) - 150 ,
                x: "-50%",
                top: "50%",
                // x: "-6.25%",
                // x: "-3.125%",
                // x: "-1.5625%",
                scrollTrigger: {
                    trigger: sections[3],
                    scrub: 1,
                    start: "+=" + sectionWidth * 3,
                    end: "+=" + sectionWidth / 2
                }
            } )
            mv04.to( circle, {
                ease: "none",
                duration: 10,
                rotation: 360,
                scale: 3,
                scrollTrigger: {
                    trigger: sections[3],
                    scrub: 1,
                    start: "+=" + sectionWidth * 3,
                    end: "+=" + sectionWidth * 1.2
                }
            } )



            // gsap.to(sections, {
            //     xPercent: -100 * (sections.length - 1),
            //     ease: "none",
            //     scrollTrigger: {
            //         trigger: heroScroll,
            //         pin: true,
            //         scrub: 1,
            //         // snap: 1 / (sections.length - 1),
            //         end: () => "+=" + document.querySelector("#js-scroll__hero").offsetWidth
            //     }
            // });
            // gsap.to(circle, {
            //     // scrollTrigger: {
            //     //     trigger: circle,
            //     //     pin: true,
            //     //     scrub: true
            //     // },
            //     rotation:360,
            //     duration: 10
            // });
        }
    }

    anchorScroll() {
        const wrapper = document.querySelector(this.wrapper);
        const circleLink = document.querySelector(this.circleLink);
        const skip = document.querySelector(this.skip);
        if(wrapper) {
            gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
            const anchors = document.querySelectorAll(this.anchor);
            anchors.forEach( (anchor) => {
                anchor.addEventListener( "click", (e) => {
                    e.preventDefault();
                    const targetSec = document.querySelector(e.currentTarget.getAttribute("href"));
                    const wrapperOffset = targetSec.offsetLeft * ( wrapper.scrollWidth / ( wrapper.scrollWidth - window.innerWidth ) ) - 30;

                    gsap.to(window, {
                        scrollTo: {
                          y: wrapperOffset,
                          autoKill: false
                        },
                        duration: 1
                    });
                } )
            })
        }
        if(circleLink) {
            circleLink.addEventListener( "click", (e) => {
                e.preventDefault();
                const offset = wrapper.scrollWidth / 4 + window.scrollY * 1.2;
                gsap.to(window, {
                    scrollTo: {
                      y: offset,
                      autoKill: false
                    },
                    duration: 0.5
                });
            })
        }
        if(skip) {
            skip.addEventListener( "click", (e) => {
                e.preventDefault();
                gsap.to(window, {
                    scrollTo: "#contents",
                    duration: 1
                });
            })
        }

    }

}