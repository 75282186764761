import "../scss/style.scss";

/**
 * @babel/polyfill の利用
 * ※ PromiseやArray.includes()など、構文ではない機能的な仕様を利用するために必要
 */
import "@babel/polyfill";

import lazysizes from "lazysizes"
import unveil from "lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js"

// if ('NodeList' in window && !NodeList.prototype.forEach) {
//     NodeList.prototype.forEach = function (callback, thisArg) {
//         thisArg = thisArg || window;
//         for (var i = 0; i < this.length; i++) {
//             callback.call(thisArg, this[i], i, this);
//         }
//     };
// }

import Slider from "./lib/Slider";
import Headroom from "./lib/Headroom";
import Lightbox from "./lib/Lightbox";
import MouseStalker from "./lib/MouseStalker";
import ScrollHorizontal from "./lib/ScrollHorizontal";
// import ScrollHorizontal02 from "./lib/ScrollHorizontal02";
import ResponsiveImages from "./lib/ResponsiveImages";
import Drawer from "./lib/Drawer";

import Accordion from "./lib/Accordion";

import SmoothScroll from "./lib/SmoothScroll";
import Loading from "./lib/Loading";

new Slider();
new Headroom();
new Lightbox();
new MouseStalker();
new ScrollHorizontal();
// new ScrollHorizontal02();
new ResponsiveImages();
new Drawer();
new Accordion();
new SmoothScroll();
new Loading();