import smoothScroll from 'smooth-scroll';

export default class {
    constructor() {
        
        this.options = {
            header: '#js-header',
            easing: 'easeInCubic',
            speed: 300,
            updateURL: false,
            offset: 0,
        }
        this.init();
    }
    init() {
        const scroll = new smoothScroll( 'a[href*="#"]', this.options);
    }
}